<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" class="text-center">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="Email"
                    v-model="email"
                    autocomplete="curent-email"
                    :invalid-feedback="errors.email"
                    :is-valid="errors.email ? false : undefined"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    :invalid-feedback="errors.password"
                    :is-valid="errors.password ? false : undefined"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButton color="primary" class="px-4"  @click="handleLogin">Login</CButton>
                    </CCol>
<!--                    <CCol col="6" class="text-right">-->
<!--                      <CButton color="link" class="px-0">Forgot password?</CButton>-->
<!--                      <CButton color="link" class="d-lg-none">Register now!</CButton>-->
<!--                    </CCol>-->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
<!--            <CCard-->
<!--              color="primary"-->
<!--              text-color="white"-->
<!--              class="text-center py-5 d-md-down-none"-->
<!--              body-wrapper-->
<!--            >-->
<!--              <CCardBody>-->
<!--                <h2>Sign up</h2>-->
<!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
<!--                <CButton-->
<!--                  color="light"-->
<!--                  variant="outline"-->
<!--                  size="lg"-->
<!--                >-->
<!--                  Register Now!-->
<!--                </CButton>-->
<!--              </CCardBody>-->
<!--            </CCard>-->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { login } from '../../api/login'
import {setToken} from "@/utils/auth";

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      passwordType: 'password',
      loading: false,
      // showDialog: false,
      // redirect: undefined,
      errors: {},
      // background: background
    }
  },
  methods: {
    // validator(val) {
    //   return val ? val.length >= 4 : false
    // },
    handleLogin() {
      // console.log(9999999)
      this.loading = true
        let loginForm = {email: this.email, password: this.password}
        this.$store.dispatch('user/login', loginForm).then(() => {
            this.$router.push({ path: this.redirect || '/' })
            this.loading = false
        }).catch((error) => {
            this.errors = error.response?.data?.errors || ''
            this.loading = false
        })
      // login(this.email, this.password).then(response => {
      //   const data = response.data
      //   // commit('SET_TOKEN', data.token)
      //   this.$store.commit('set', ['token', data.token])
      //   this.$store.commit('set', ['userInfo', data.user])
      //   setToken(response.data.token)
      //   this.loading = false
      //   this.$router.push({ path: this.redirect || '/' })
      // }).catch(error => {
      //   // console.log(999999999, error.response?.data?.errors)
      //   this.errors = error.response?.data?.errors || ''
      //   this.loading = false
      //
      // })
    }
  }
}
</script>
